/** @jsx jsx */
import { GatsbyImage } from "gatsby-plugin-image"

import { Heading } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

const BlogHighlightsSlider = ({ items, sx }) => {
  if (!Array.isArray(items) || !items.length) {
    return null
  }

  return (
    <div
      sx={{
        position: "relative",
        width: ["calc(100% + 24px)", "100%"],
        mx: [-3, 0],
        ...sx,
      }}
    >
      <div
        sx={{
          display: ["flex", "grid"],
          gridTemplateColumns: [null, "repeat(3, 1fr)"],
          gap: [null, 5],
          flexDirection: ["row", "initial"],
          flexWrap: ["nowrap", "initial"],
          overflowX: ["auto", "initial"],
          scrollSnapType: "x mandatory",
          webkitOverflowScrolling: "touch",
        }}
      >
        {items.map((item, indx) => (
          <div
            key={`${item.title?.replace(/\s/gi, "-").toLowerCase()}-${indx}`}
            sx={{
              flexShrink: 0,
              width: ["calc(75% - 12px)", "auto"],
              mx: [3, 0],
              scrollSnapAlign: "start",
              scrollSnapStop: "always",
              scrollBehavior: "smooth",
              scrollMarginLeft: ["12px", 0],
            }}
          >
            <GatsbyImage
              image={item.image.imageFile.childImageSharp.gatsbyImageData}
              alt=""
              sx={{
                height: [335, 424, 536],
                mb: 5,
              }}
            />

            <Heading as="h3" mb={5}>
              {item.title}
            </Heading>

            <p sx={{ my: 0 }}>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BlogHighlightsSlider
