/** @jsx jsx */
import { GatsbyImage } from "gatsby-plugin-image"
import { useEffect, useRef } from "react"

import { Heading, Icon, TextLink } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"
import { arrowRightIcon } from "@trueskin-web/theme/icons/arrowRightIcon"
import { Trans } from "@trueskin-web/translations"

const getCategoryArticles = (blogCategoryPostsCount, categoryId) =>
  blogCategoryPostsCount.find((x) => x.categoryId === categoryId).count

const CategoryBox = ({
  id,
  heading,
  description,
  image,
  background,
  slug,
  blogCategoryPostsCount,
}) => {
  const paragraphRef = useRef()

  useEffect(() => {
    const { current } = paragraphRef

    if (!current) {
      return
    }

    const height = current.offsetHeight
    const numLines = Math.floor(height / 30)

    current.style["-webkit-line-clamp"] = numLines
  }, [description, paragraphRef])

  return (
    <TextLink
      href={`/${slug}/`}
      title={heading}
      isTextColor
      sx={{
        position: "relative",
        display: "block",
        background,
      }}
    >
      <div
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: ["column", "row"],
          background,
        }}
      >
        <div
          sx={{
            flexShrink: 0,
            size: [null, 260],
          }}
        >
          <GatsbyImage
            image={image.imageFile.childImageSharp.gatsbyImageData}
            alt=""
            sx={{
              height: [null, 260],
              maxHeight: [320, null],
            }}
          />
        </div>

        <div
          sx={{
            position: "relative",
            flexGrow: 1,
            p: 4,
            pb: 8,
            maxHeight: [null, 260],
          }}
        >
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              height: [null, "100%"],
              maxHeight: ["160px", "unset"],
            }}
          >
            <Heading
              as="h4"
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: "5",
                flexGrow: 0,
                flexShrink: 0,
                maxHeight: 135,
                overflow: "hidden",
              }}
            >
              {heading}
            </Heading>
            <div
              ref={paragraphRef}
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                minHeight: 0,
                overflow: "hidden",
              }}
            >
              <p
                sx={{
                  flexGrow: 0,
                  flexShrink: 1,
                }}
              >
                {description}
              </p>
            </div>
          </div>

          <div
            sx={{
              position: "absolute",
              bottom: "-1px",
              transform: "translateY(-100%)",
              display: "block",
              width: "calc(100% - 24px)",
              borderBottom: "1px",
            }}
          >
            <div
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                fontSize: [4, 4],
                lineHeight: "heading",
              }}
            >
              <Trans i18nKey="CategoryBox.readMore" />
              <Icon icon={arrowRightIcon} />
            </div>
          </div>
        </div>

        {/* Article counter */}
        <div
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            px: 3,
            py: 2,
            color: "background",
            backgroundColor: "black",
            fontSize: 0,
            fontFamily: "monospace",
          }}
        >
          <Trans
            i18nKey="CategoryBox.items"
            values={{ count: getCategoryArticles(blogCategoryPostsCount, id) }}
          />
        </div>
      </div>
    </TextLink>
  )
}

export default CategoryBox
