/** @jsx jsx */
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import { Fragment, useEffect } from "react"

import { trackingClient } from "@trueskin-web/apis"
import { Container } from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import BlogHero from "../block-library/blog-hero"
import BlogHighlights from "../block-library/blog-highlights"
import CategoryBox from "../block-library/category-box"
import Blocks from "../block-library/components/blocks"
import SEODetails from "../block-library/components/seo-details"
import Layout from "../components/layout"

const BlogPage = ({
  data: {
    strapi: { blogIndexPage, blogCategoryPostsCount },
  },
}) => {
  const location = useLocation()

  useEffect(() => {
    trackingClient.trackCampaignIfPresent(location)
  }, [location])

  return (
    <Layout title={blogIndexPage.title}>
      <SEODetails title={blogIndexPage.title} {...blogIndexPage.seoDetails} />

      {blogIndexPage.blocks?.length ? (
        <Blocks blocks={blogIndexPage.blocks} />
      ) : (
        <Fragment>
          <BlogHero {...blogIndexPage.blogHero} />

          <Container>
            <div sx={{ mt: [7, 9], mb: [9, 10] }}>
              <div
                sx={{
                  display: "grid",
                  gridTemplateColumns: ["auto", "repeat(2, 1fr)"],
                  columnGap: [null, 5],
                  rowGap: [5, 5],
                }}
              >
                {blogIndexPage.blogCategories.map((blogCategory) => (
                  <CategoryBox
                    key={blogCategory.heading
                      ?.replace(/\s/gi, "-")
                      .toLowerCase()}
                    id={blogCategory.id}
                    heading={blogCategory.heading}
                    description={blogCategory.description}
                    image={blogCategory.image}
                    background={blogCategory.backgroundColor}
                    name={blogCategory.name}
                    slug={blogCategory.slug}
                    blogCategoryPostsCount={blogCategoryPostsCount}
                  />
                ))}
              </div>
            </div>
          </Container>

          <BlogHighlights {...blogIndexPage.highlights} />
        </Fragment>
      )}
    </Layout>
  )
}

export const query = graphql`
  {
    strapi {
      blogIndexPage {
        title
        seoDetails {
          ...SEODetailsFragment
        }
        heading
        description
        blogHero {
          ...BlogHeroFragment
        }
        highlights {
          ...BlogHighlightsFragment
        }
        blogCategories {
          id
          name
          heading
          description
          backgroundColor
          slug
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(width: 500, aspectRatio: 1, layout: CONSTRAINED)
              }
            }
          }
        }
        blocks {
          __typename
          ... on Strapi_ComponentBlocksHero {
            ...HeroFragment
          }
          ... on Strapi_ComponentBlocksSmallHero {
            ...SmallHeroFragment
          }
          ... on Strapi_ComponentBlocksTextHero {
            ...TextHeroFragment
          }
          ... on Strapi_ComponentLandingContentCtaList {
            ...ContentCtaListFragment
          }
          ... on Strapi_ComponentLandingList {
            ...ListFragment
          }
          ... on Strapi_ComponentLandingContentCtaBadges {
            ...ContentCtaBadgesFragment
          }
          ... on Strapi_ComponentLandingBadges {
            ...BadgesFragment
          }
          ... on Strapi_ComponentBlocksProcess {
            ...ProcessFragment
          }
          ... on Strapi_ComponentBlocksHighlights {
            ...HighlightsFragment
          }
          ... on Strapi_ComponentBlocksContentCta {
            ...ContentCtaFragment
          }
          ... on Strapi_ComponentBlocksContentVideo {
            ...ContentVideoFragment
          }
          ... on Strapi_ComponentBlocksPrStrip {
            ...PrStripFragment
          }
          ... on Strapi_ComponentAtomsImage {
            ...ImageFragment
          }
          ... on Strapi_ComponentAtomsSpace {
            ...SpaceFragment
          }
          ... on Strapi_ComponentAtomsRichContent {
            ...RichContentFragment
          }
          ... on Strapi_ComponentBlocksReviews {
            ...ReviewsFragment
          }
          ... on Strapi_ComponentBlocksHowItWorks {
            ...HowItWorksFragment
          }
          ... on Strapi_ComponentBlocksDoctorsIntro {
            ...DoctorsIntroFragment
          }
          ... on Strapi_ComponentBlocksProductsHighlight {
            ...ProductsHighlightFragment
          }
          ... on Strapi_ComponentBlocksBeforeAfter {
            ...BeforeAfterFragment
          }
          ... on Strapi_ComponentBlocksMirroredHighlights {
            ...MirroredHighlightsFragment
          }
          ... on Strapi_ComponentBlocksPrefooter {
            ...PrefooterFragment
          }
          ... on Strapi_ComponentBlocksDoctors {
            ...DoctorsFragment
          }
          ... on Strapi_ComponentBlocksShowcaseProducts {
            ...ShowcaseProductsFragment
          }
          ... on Strapi_ComponentBlocksHowItWorksVisually {
            ...HowItWorksVisuallyFragment
          }
          ... on Strapi_ComponentBlocksFullSizeHero {
            ...FullSizeHeroFragment
          }
          ... on Strapi_ComponentBlocksLpHero {
            ...LpHeroFragment
          }
          ... on Strapi_ComponentBlocksTreatmentGoals {
            ...TreatmentGoalsFragment
          }
          ... on Strapi_ComponentBlocksDoctorsBoard {
            ...DoctorsBoardFragment
          }
          ... on Strapi_ComponentBlocksFaqs {
            ...FaqsFragment
          }
          ... on Strapi_ComponentBlocksConditionsList {
            ...ConditionsListFragment
          }
          ... on Strapi_ComponentBlocksHowItWorksGuide {
            ...HowItWorksGuideFragment
          }
          ... on Strapi_ComponentBlocksMedicalIntro {
            ...MedicalIntroFragment
          }
          ... on Strapi_ComponentBlocksSkinGuideIndex {
            ...SkinGuideIndexFragment
          }
          ... on Strapi_ComponentBlocksSetIntro {
            ...SetIntroFragment
          }
          ... on Strapi_ComponentBlocksHeroTrustpilot {
            ...HeroTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksHeroPriceTrustpilot {
            ...HeroPriceTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksProgressBoard {
            ...ProgressBoardFragment
          }
          ... on Strapi_ComponentBlocksInstagramWidget {
            ...InstagramWidgetFragment
          }
          ... on Strapi_ComponentBlocksSocialReviews {
            ...SocialReviewsFragment
          }
          ... on Strapi_ComponentBlocksBannerUsp {
            ...BannerUspFragment
          }
          ... on Strapi_ComponentBlocksMedicHero {
            ...HeroMedicFragment
          }
          ... on Strapi_ComponentBlocksDoctorTeam {
            ...DoctorTeamFragment
          }
          ... on Strapi_ComponentBlocksDoctorMembers {
            ...DoctorMembersFragment
          }
          ... on Strapi_ComponentBlocksTitle {
            ...TitleFragment
          }
          ... on Strapi_ComponentBlocksThreePartContent {
            ...ThreePartContentFragment
          }
          ... on Strapi_ComponentBlocksZigZag {
            ...ZigZagFragment
          }
          ... on Strapi_ComponentBlocksConnector {
            ...ConnectorFragment
          }
          ... on Strapi_ComponentBlocksTimeline {
            ...TimelineFragment
          }
          ... on Strapi_ComponentBlocksProductCards {
            ...ProductCardsFragment
          }
          ... on Strapi_ComponentBlocksIngredientCards {
            ...IngredientCardsFragment
          }
          ... on Strapi_ComponentBlocksCtaBlock {
            ...CtaBlockFragment
          }
          ... on Strapi_ComponentBlocksNewsletterSignup {
            ...NewsletterSignupFragment
          }
        }
      }
      blogCategoryPostsCount {
        categoryId
        count
      }
    }
  }
`

export default BlogPage
