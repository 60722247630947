/** @jsx jsx */
import { graphql } from "gatsby"

import {
  Button,
  Container,
  FakeSelect,
  Heading,
} from "@trueskin-web/components"
import { jsx } from "@trueskin-web/theme"

import BlogHighlightsSlider from "./blog-highlights-slider"

const BlogHighlights = ({
  title,
  description,
  items,
  primaryCta,
  onHomepage,
}) => {
  return (
    <div
      sx={{
        variant: "layout.sectionPadding",
        bg: "lightRose",
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyItems: "flex-start",
          alignItems: [null, null, "flex-start"],
        }}
      >
        <div
          sx={{
            flexBasis: ["100%", "50%"],
            flexShrink: 0,
            order: 1,
            pr: [null, 3, 5],
            mb: [7, 0],
          }}
        >
          <Heading size="lg" sx={{ mb: 5 }}>
            {title}
          </Heading>
          <p sx={{ my: 0, fontSize: 3 }}>{description}</p>
        </div>

        <div
          sx={{
            display: "flex",
            flexDirection: ["column", "row"],
            flexBasis: ["100%", "50%"],
            justifyContent: ["flex-end"],
            flexGrow: 1,
            flexShrink: 0,
            order: [3, 2],
            mb: [7, 0],
            alignSelf: "flex-end",
          }}
        >
          <FakeSelect sx={{ fontSize: 2 }} />

          <Button
            onClick={() => {
              Object.assign(document.createElement("a"), {
                href: primaryCta.targetUrl,
              }).click()
            }}
            {...(onHomepage && {
              id: "highlights-cta",
            })}
            sx={{
              mt: [4, 0],
              ml: [null, 3, 4],
              px: [null, 5, 8],
            }}
          >
            {primaryCta.label}
          </Button>
        </div>

        <div
          sx={{
            width: "100%",
            flexGrow: 1,
            flexShrink: 0,
            order: [2, 3],
            mt: [0, 7],
            mb: 7,
          }}
        >
          <BlogHighlightsSlider items={items} />
        </div>

        {/*
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["repeat(3, 260px)", "repeat(3, 1fr)"],
            gap: 5,
            gridColumn: [null, null, "span 8"],
            mx: [-4, 0],
            px: [4, 0],
            overflowX: "auto",
            scrollSnapType: "x mandatory",
          }}
        >
        </div>
        */}
      </Container>
    </div>
  )
}

export default BlogHighlights

export const blogHighlightsFragment = graphql`
  fragment BlogHighlightsFragment on Strapi_ComponentBlocksHighlights {
    title
    description
    items {
      title
      description
      image {
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 260, quality: 50, layout: CONSTRAINED)
          }
        }
      }
    }
    primaryCta {
      label
      targetUrl
    }
  }
`
